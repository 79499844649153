* {
  font-family: 'Courier New', Courier, monospace;
  font-size: 1rem;
  background-color: #1f1f1f;
  color: white;
  text-decoration: none;
  margin: 0 !important;
}

.cli {
  padding: 2%;
}

.cliopt {
  background: none!important;
  border: none;
  padding: 0!important;
  cursor: pointer;
  color: #bafcc1;
}

a {
  color: #bafcc1;
}

input {
  outline: none;
  border: none;
}

.inline {
  display: inline-block;
  size: 100%;
}

.cursor {
  animation: blinker 2s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.flex-container {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 750px) {
  .flex-container {
      flex-direction: column;
  }
}

.flex-container > * {
  margin: 10px;
  padding: 20px;
}

.profile-pic {
  height: 200px;
}

.logo {
  height: 100px;
}

